import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import Vue2Filters from 'vue2-filters'
import VueTheMask from 'vue-the-mask'
import money from 'v-money'

Vue.use(money, {precision: 4})

Vue.use(VueTheMask)
Vue.use(Vue2Filters)
Vue.use(Vuetify);

Vue.prototype.$filters = Vue.options.filters

export default new Vuetify({});
