<template>
  <div fluid>
  <v-row v-if="!$vuetify.breakpoint.smAndDown">
    <v-card
      flat
      :color="config.color"
      :width="config.width"
      :height="config.height"
      style="border-radius: 0px;"
    >
      <v-img
        :src="config.src"
        :width="config.width"
        :height="config.height"
        :contain="config.contain"
      >
        <slot></slot>
      </v-img>
    </v-card>
  </v-row>
  <v-row v-else-if="$vuetify.breakpoint.smAndDown">
    <v-card
      flat
      :color="config.color"
      :width="config.width"
      :height="config.height"
      style="border-radius: 0px; background-color: #f4f6f9"
    >
      <v-container>
        <slot></slot>
      </v-container>
    </v-card>
  </v-row>
  </div>
</template>

<script>
  export default {
    name: 'Header',
    computed: {
      config () {
        return this.$store.state.header;
      }
    },
    data: () => ({
    })
  }
</script>