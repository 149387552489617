<template>
  <v-container fluid class="pa-10 mt-10">
      <v-card
        id="benefits"
        flat
        width="100%"
        class="pa-0 text-center"
      >
            <h4 class="display-1 text-uppercase">{{benefits.title}}</h4>
            <p class="mt-3 text--secondary">{{benefits.subTitle}}</p>
            <v-row class="mt-10 pa-0">
              <v-col
                v-for="(item, i) in benefits.items"
                :key="i"
                cols="12"
                md="4"
                sm="12"
              >
                <v-card flat class="text-left">
                  <div class="d-flex flex-no-wrap">
                    <v-avatar
                      class=""
                      size="80"
                      tile
                    >
                      <v-img :src="item.img"></v-img>
                    </v-avatar>
                    <div>
                      <v-card-title
                        class="subtitle-2 text-uppercase"
                        v-html="item.title"
                      ></v-card-title>
                      <v-card-subtitle v-html="item.text"></v-card-subtitle>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
      </v-card>
      <v-card
        id="requirements"
        flat
        width="100%"
        class="pa-2 mt-10 text-center"
      >
            <h4 class="display-2 text-uppercase">{{requirements.title}}</h4>
            <p class="mt-3 text--secondary">{{requirements.subTitle}}</p>
            <v-row class="mt-10 pa-10">
              <v-col
                v-for="(item, i) in requirements.items"
                :key="i"
                md="4"
                sm="12"
              >
                <v-card flat class="text-center">
                  <div class="">
                    <v-avatar
                      class="ma-3"
                      size="80"
                      tile
                    >
                      <v-img :src="item.img"></v-img>
                    </v-avatar>
                    <div class="text-center">
                      <div
                        class="subtitle-2 text-uppercase"
                        v-html="item.title"
                      ></div>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
      </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'Benefits',
    computed: {
      benefits () {
        return this.$store.state.benefits;
      },
      requirements () {
        return this.$store.state.requirements;
      }
    },
    data: () => ({
    })
  }
</script>