<template>
  <v-footer
      app
      :color="footer.color"
      class="mt-10"
      padless
      absolute
    >
      <v-col
          :color="footer.color"
          class="pa-10 text-left white--text"
          cols="12"
          md="4"
          sm="12"
        >
        <a :href="''">
          <v-img
          :width="$vuetify.breakpoint.smAndDown ? '100%' : '250'"
          :src="footer.img"
          ></v-img>
        </a>
      </v-col>
      <v-col
        :color="footer.color"
        class="pa-10 text-left white--text"
        cols="12"
        md="8"
        sm="12"
      >
      <p>
        La presente no implica oferta de crédito ni aceptación de la solicitud de crédito. Es solo para efectos ilustrativos. Otorgamiento sujeto a la previa verificación de antecedentes crediticios y a condiciones previas de contratación y aprobación definitiva por parte de Industrial and Commercial Bank of China (Argentina) S.A. Industrial and Commercial Bank of China (Argentina) S.A. es una sociedad anónima bajo la ley argentina. Sus accionistas limitan su responsabilidad al capital aportado (Ley 25.738). TNA: Tasa Nominal Anual.TEA: Tasa Efectiva Anual. CFT: Costo Financiero Total.
      </p>
      </v-col>
      <v-btn
        v-for="(link, key) in footer.links"
        :key="key"
        color="white"
        text
        rounded
        class="my-2"
        @click="goTo(link)"
      >
        {{ link.texto }}
      </v-btn>
      <v-col
        :color="footer.color"
        class="text-center white--text"
        cols="12"
      >
      Powered by <strong>SimpliCar</strong>
      </v-col>
      <v-col
        :color="footer.color"
        class="text-center white--text"
        cols="12"
      >
      {{footer.version}}
      </v-col>
  </v-footer>
</template>

<script>
  export default {
    name: 'Footer',
    computed: {
      footer () {
        return this.$store.state.footer;
      }
    },
    data: () => ({
    }),
    methods: {
      goTo (item) {
        this.$vuetify.goTo(item.href);
      }
    }
  }
</script>
